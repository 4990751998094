var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "preview-banner" }, [
      _c("div", { staticClass: "columns is-mobile" }, [
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
        _c("div", { staticClass: "column" }, [
          _c("h3", [_vm._v("Event Preview")]),
          _c(
            "button",
            {
              staticClass: "button is-pulled-right",
              on: {
                click: function($event) {
                  return _vm.edit()
                }
              }
            },
            [_vm._m(0), _c("span", [_vm._v("Edit Event")])]
          )
        ]),
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" })
      ])
    ]),
    _c("div", { staticClass: "body-wrapper" }, [
      _c("div", { staticClass: "columns" }, [
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
        _c("div", { staticClass: "column maincont" }, [
          _c("div", { staticClass: "imagewrap" }, [
            _c("img", {
              staticClass: "event-image",
              attrs: { src: _vm.event.image }
            })
          ]),
          _c(
            "div",
            { staticClass: "calendar" },
            [
              _c("div", { staticClass: "cal-stripe" }),
              _vm.event && _vm.event.start
                ? _c("EventDateBadge", {
                    attrs: { date: _vm.event.start.toDate() }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "tix-button" }, [
            _c(
              "button",
              {
                staticClass: "button is-primary",
                attrs: {
                  disabled:
                    _vm.salesStatus == "Sold Out" ||
                    _vm.salesStatus == "Sales Closed"
                },
                on: {
                  click: function($event) {
                    return _vm.tickets()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.salesStatus) + " ")]
            )
          ]),
          _c(
            "div",
            { staticClass: "description indent" },
            [
              _vm.venue
                ? _c(
                    "div",
                    {
                      staticClass: "venue-map",
                      class: {
                        hasMap:
                          !_vm.event.mapDisplay ||
                          _vm.event.mapDisplay != "hidden"
                      }
                    },
                    [
                      !_vm.event.mapDisplay || _vm.event.mapDisplay != "hidden"
                        ? _c(
                            "gmap-map",
                            {
                              staticStyle: { width: "300px", height: "200px" },
                              attrs: {
                                center: _vm.location,
                                zoom: _vm.event.venueIsAlternative ? 10 : 17,
                                mapTypeControl: "false",
                                options: _vm.options,
                                zoomControl: "false"
                              }
                            },
                            [
                              _c("gmap-marker", {
                                attrs: {
                                  position: _vm.location,
                                  clickable: true,
                                  draggable: true
                                },
                                on: {
                                  click: function($event) {
                                    _vm.center = _vm.location
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "map-legend" }, [
                        _c("h3", [_vm._v(_vm._s(_vm.venue.name))]),
                        !_vm.event.venueIsAlternative
                          ? _c("p", [_vm._v(_vm._s(_vm.venue.fullAddress))])
                          : _vm._e(),
                        _vm.event.venueIsAlternative
                          ? _c("p", [_vm._v(_vm._s(_vm.venue.city))])
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [_c("EventDateline", { attrs: { event: _vm.event } })],
                1
              ),
              _c("h1", [_vm._v(_vm._s(_vm.event.title))]),
              !_vm.event.artists
                ? _c("p", { staticClass: "act" }, [
                    _vm._v(_vm._s(_vm.event.act))
                  ])
                : _vm._e(),
              !_vm.event.artists
                ? _c("p", { staticClass: "act" }, [
                    _vm.artist && _vm.artist.image
                      ? _c("img", {
                          attrs: { src: _vm.artist.image, alt: "artist.name" }
                        })
                      : _vm._e(),
                    !_vm.artist || !_vm.artist.urlslug
                      ? _c("span", [_vm._v(_vm._s(_vm.event.act))])
                      : _vm._e(),
                    _vm.artist && _vm.artist.urlslug
                      ? _c(
                          "a",
                          { attrs: { href: "/artist/" + _vm.artist.urlslug } },
                          [_vm._v(_vm._s(_vm.artist.name))]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._l(_vm.event.artists, function(artist) {
                return _c("p", { key: artist.id, staticClass: "act" }, [
                  artist && artist.image
                    ? _c("img", {
                        attrs: { src: artist.image, alt: "artist.name" }
                      })
                    : _vm._e(),
                  !artist || !artist.urlslug
                    ? _c("span", [_vm._v(_vm._s(artist.name))])
                    : _vm._e(),
                  artist && artist.urlslug
                    ? _c(
                        "a",
                        { attrs: { href: "/artist/" + artist.urlslug } },
                        [_vm._v(_vm._s(artist.name))]
                      )
                    : _vm._e()
                ])
              }),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.event.description) }
              })
            ],
            2
          ),
          _c("div", { staticClass: "performances indent" }, [
            _c("h3", [_vm._v("Performance Dates")]),
            _c(
              "table",
              { attrs: { id: "datetable" } },
              [
                _vm._m(1),
                _vm._l(_vm.eventDates, function(date) {
                  return _c(
                    "tr",
                    {
                      key: date.id,
                      class: { pastevent: date.start.toDate() < Date.now() }
                    },
                    [
                      _c("td", [
                        _c("span", { staticClass: "is-hidden-mobile" }, [
                          _vm._v(_vm._s(_vm.formatDate(date.startDate)))
                        ]),
                        _c("span", { staticClass: "is-hidden-tablet" }, [
                          _vm._v(_vm._s(_vm.formatShortDate(date.startDate)))
                        ])
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatTime(date.startTime)))
                      ]),
                      _c(
                        "td",
                        [
                          _vm._l(date.tags, function(tag) {
                            return _c(
                              "span",
                              { key: tag, staticClass: "tag is-info" },
                              [_vm._v(" " + _vm._s(tag) + " ")]
                            )
                          }),
                          date.status == "soldout"
                            ? _c("span", { staticClass: "soldout" }, [
                                _vm._v("Sold Out!")
                              ])
                            : _vm._e(),
                          date.status == "limited"
                            ? _c("span", { staticClass: "limited" }, [
                                _vm._v("Almost Gone!")
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
        ]),
        _c("div", { staticClass: "column is-one-fifth" })
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-pen" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { span: "1" } }),
      _c("col", { attrs: { span: "1" } }),
      _c("col", { staticStyle: { width: "30%" }, attrs: { span: "1" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }